export let dateMixin= {
    methods:{
        //spécifique à monechappeebelle
        getTime(dateString){
            return dateString.slice(11,16)
        },

        // date String en DD-MM-YYYY HH:MM
        formatDate(dateString){
            let utc = dateString.charAt(21)
            // utc == heure 
            let date = new Date(dateString)
            let originalUTC = date.toISOString().split('T')[1].slice(0,5)
            let getHour = parseInt(originalUTC.split(':')[0])
            let displayedHour = getHour + parseInt(utc)
            date = date.toISOString().split('T')[0]+' '+ displayedHour.toString()+date.toISOString().split('T')[1].slice(2,5)
            return date
        },
        // date String en DD-MM-YYYY
        formatDate2(date){
            // récupérer le jour, mois et année
            let date1= date.substring(0,11)
            // diviser par -
            let date2 = date1.split('-')
            // reformater
            let date3 = date2[2]+'-'+date2[1]+'-'+date2[0]
            //supprimer le T de la date
            let date4 = date3.replace('T','')
            return date4
        },
        // format date to DD-MM-YYYY HH:ii -- string
        formatDate3(maDate){
            // Récupérez l'année, le mois, le jour, l'heure et les minutes
            const annee = maDate.getFullYear();
            const mois = (maDate.getMonth() + 1).toString().padStart(2, '0'); // Mois commence à 0, ajoutez 1 et formatez avec '0' si nécessaire
            const jour = maDate.getDate().toString().padStart(2, '0'); // Formatez avec '0' si nécessaire
            const heures = maDate.getHours().toString().padStart(2, '0'); // Formatez avec '0' si nécessaire
            const minutes = maDate.getMinutes().toString().padStart(2, '0'); // Formatez avec '0' si nécessaire

            // Créez la chaîne au format "YYYY-MM-DD HH:MM"
            const dateString = annee + '-' + mois + '-' + jour + ' ' + heures + ':' + minutes;
            return dateString
        },
        // ajout des 10 minutes à la fin des dates de fin -- date_parameter = DD-MM-YYYY HH:MM
        // addTenMinutesToTime(date_parameter){
        //     // let date_day = date_parameter.split(' ')[0]
        //     // let date_time = date_parameter.split(' ')[1]
        //     let date = new Date(date_parameter);
        //     date.setMinutes(date.getMinutes() + 10);
        //     let time = date.toTimeString().slice(0,5)
        //     return date_day +' '+ time
        // },

        // date = DD-MM-YYYYTHH:MM:00
        addTenMinutesToTime(date){
            date.setMinutes(date.getMinutes() + 10);
            return date
        },
        // ajouter la durée des prestations au début pour éviter le chevauchement si prestation très longue
        // date_parameter_with_timezone = string, duration -- ex :: 0.5
        reduceMinutes(date_parameter_with_timezone,duration){
            let hour_parameter = date_parameter_with_timezone.split('T')[1].substring(0,5)
            let date_day = date_parameter_with_timezone.split('T')[0]
            let date_parameter = `${date_day} ${hour_parameter}`
            const date = new Date(date_parameter);
            date.setMinutes(date.getMinutes() - (duration*60));
            let time = date.toTimeString().slice(0,5)
            return date_day +' '+ time
        },
        reduceMinutesSpace(date_parameter_with_timezone,duration){
            let hour_parameter = date_parameter_with_timezone.split(' ')[1].substring(0,5)
            let date_day = date_parameter_with_timezone.split(' ')[0]
            let date_parameter = `${date_day} ${hour_parameter}`
            const date = new Date(date_parameter);
            date.setMinutes(date.getMinutes() - (duration*60));
            let time = date.toTimeString().slice(0,5)
            return date_day +' '+ time+":00"
        },
        calculTimesWithHHMM(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            let heure = Number(timeString.substr(0,2))
            let min = Number(timeString.substr(3,5))
            let stringHeure = ''
            if (heure) {
                stringHeure = heure+ ' h'
            }
            if (min) {
                stringHeure += min + 'mn'
            }
            return stringHeure
        }
    }
}