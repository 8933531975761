<template>
    <section class="cream-background">
        <!-- loader -->
    <div v-if="loader" class="jumping-dots-loader">
        <span></span>
        <span></span>
        <span></span>
    </div>

    <pageLoader v-if="loadershow" :loadingText="message"/>
        <div class="content-wrapper">
            <h2>Rendez-vous</h2>

            <h3>Choisir une prestation</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex justify-content-center">
                        <!-- mobile -->
                        <add-service class="mobile" v-if="$store.state.cart.services.length==0"
                        :modalId="'addservice2'"
                        text="Ajouter un rendez-vous"
                        key="addservice2"
                        @updateService="addService($event)"
                        />
                    </div>
                    <!-- écran large -->
                    <div v-if="$store.state.cart.services.length !==0" id="prestations" class="table-responsive computer">
                        <table class="table">
                            <thead>
                                <th>Prestation</th>
                                <th>Durée</th>
                                <th class="text-right">Tarif</th>
                                <th class="text-right">Action</th>
                            </thead>
                            <tbody v-if="!$store.state.cart.services.length">
                                <tr>
                                    <td class="text-muted">Veuillez choisir une prestation</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, itemid) in cart" :key="itemid">
                                    <td>{{item.prestation.name}}</td>
                                    <td>{{item.duration}}</td>
                                    <td style="text-align:right">{{item.price}} €</td>
                                    <td>
                                        <span class="d-flex justify-content-end">
                                            <button class="btn btn-primary btn-sm mx-3" style="background: #cda525;color: #ffffff;border:none" @click="removeServiceToCart(item)">
                                                <i class="fa fa-trash pointer" ></i>
                                            </button>
                                            <!-- <add-service-plus v-if="checkDate(item)"  :selectedDateTime="item.datepurchase.substr(0,10)" :modalKey="itemid" :key="'plus'+skey" :myDate="myDate" :myTime="dateString(item.endDate).substr(11,5)" @updateServicePLus="plusaddservice($event)"/> -->
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <th>Total</th>
                                <th></th>
                                <th style="text-align:right">{{totalpice}} €</th>
                                <th class="text-right">Prix total</th>
                            </tfoot>
                        </table>
                    </div>

                    <!-- sélection prestations ecran large -->
                    <div class="d-flex ">
                        <!-- Ecran large -->
                        <add-service class="computer"
                            :modalId="'addservice1'"
                            text="Ajouter une prestation"
                            key="addservice1"
                            @updateService="addService($event)"
                        />
                    </div>

                    <!-- HORAIRE -->
                    <!-- heure et date de début -->
                    <div class="d-flex justify-content-center">
                        <div class="card computer" style="width:40%; display:none!important">
                            <div class="card-header">
                                <div data-v-f2b16ec4="" class="wrapper d-flex align-items-center justify-content-center media-info primary"><h2 class="card-title">Horaire</h2></div>
                            </div>
                            <div class="card-body">
                                <div class="flex-column">
                                    <div class="form-group">
                                        <label for="datepicker" class="d-block">Date de réservation</label>
                                        <!-- @open=key_time_picker permet de mettre à jour le composant time picker -->
                                            <date-picker class="mt-2"
                                            v-model="mobile.date"
                                            valueType="YYYY-MM-DD"
                                            format="DD-MM-YYYY"
                                            placeholder="Choisir une date"
                                            :disabled-date="disabledDate"
                                            @pick="key_time_picker+=1" 
                                            >
                                        </date-picker>
                                    </div>
                                    <div class="form-group">
                                        <label for="datepicker" class="d-block">Heure de début</label>
                                            <time-picker-meb class="mt-2"
                                                v-model="mobile.start"
                                                :key="`${mobile.date}key_time_picker`"
                                                type="input"
                                                :modalId="'modal-computer-time-picker'"
                                                :duration="totalDuration"
                                                :selectedDateTime="mobile.date.substr(0,10)"
                                                :myDate="myDate"
                                                :myTime="timeoncall"
                                                @saveTime="saveTimeMobile" 
                                                @close_loader="loadershow=false"
                                                />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--affichage sur mobile -->
                    <div class="reservation-mobile mobile card p-1">
                        <!-- liste des prestations -->
                        <div class="card-body position-relative my-1 d-flex justify-content-between align-items-center" v-for="(item, itemid2) in cart" :key="itemid2">
                            <div class="wrapper ml-3">
                            <label title="En construction" class="badge badge-primary construct pointer" style="z-index:1"><i class="mdi mdi-close" @click="removeServiceToCart(item)"></i></label>
                                <h6 class="ml-1 mb-1">{{item.prestation.name}}</h6>
                                <small class="text-muted mb-0"><i class="mdi mdi-timelapse  mr-1"></i>{{item.duration}}</small>
                            </div>
                            <h3>{{item.price}}€</h3>
                        </div>
                        <!-- ajouter une prestation à la suite -->
                        <div class="d-flex justify-content-center">
                            <add-service :modalId="'mobileaddservice'" :key="'mobileaddservice'" v-if="$store.state.cart.services.length>0" text="Ajouter un rdv à la suite" @updateService="addService($event)"/>
                        </div>
                        <!-- heure et date de début mobile -->
                        <div class="row mt-3">
                            <div class="col-12" v-if="cart.length !== 0">
                                <h4>Choisir la date du rendez vous</h4>
                                <date-picker class="mt-2"
                                v-model="mobile.date"
                                :disabled-date="disabledDate"
                                valueType="YYYY-MM-DD"
                                format="DD-MM-YYYY"
                                placeholder="Choisir une date"
                                @pick="key_time_picker+=1"
                                >
                            </date-picker>
                            </div>
                            <!-- mobile -->
                            <div class="col-12 mt-3" v-if="mobile.date && cart.length !== 0">
                                <h4>Choisir l'heure du rendez vous</h4>
                                <time-picker-meb class="mt-2"
                                v-model="mobile.start"
                                :key="`${mobile.date}key_time_picker_mobile${fulltimepickerkey}`"
                                type="input"
                                :modalId="'modal-mobile-time-picker'"
                                :duration="totalDuration"
                                :selectedDateTime="mobile.date.substr(0,10)"
                                :myDate="myDate"
                                :open_modal="modaltriger"
                                @saveTime="saveTimeMobile" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- FULL CALENDAR / AGENDA -->
            <!-- <span id="fulltimepicker" class="computer" v-if="!mobile.start"> -->
            <h3 class="mt-3" v-if="!mobile.start">Définir un horaire</h3>
            <span id="fulltimepicker" v-if="!mobile.start">
                <div class="d-flex justify-content-between">
                    <label class="computer" for="">Cliquer dans l'agenda pour choisir la date de votre rendez-vous</label>
                    <span class="d-flex align-items-center ">
                        <div class="unavailable_square m-2"></div>
                        dates indisponibles
                    </span>
                </div>
                <fullTimePickerMeb @selected_time="cart.length==0?alertPrestation():(loadershow=true, mobile.date = $event[0] , timeoncall = $event[1])" :key="fulltimepickerkey" :myreservation="myreservation" />
            </span>

            <!-- résumé de la réservation -->
            <div class="border mt-5" id="resume" v-if="mobile.start">
                <div class="border_gold">
                    <span class="d-flex flex-column flex-sm-row">
                    <h4>Prestations choisies</h4>
                    <div class="input_resume">
                        <span>
                            <h5 class="text-muted" v-if="cart.length == 0">Prestation vide</h5>
                            <span v-else>
                                <h5 v-for="(item) in cart" :key="item.ulid">- {{item.prestation.name}}</h5>
                            </span>
                            <!-- <ul v-else>
                                <li v-for="(item) in cart" :key="item.ulid">{{item.prestation.name}}</li>
                            </ul> -->
                        </span>
                    </div>
                    </span>

                    <span class="d-flex align-items-center flex-column flex-sm-row mt-1">
                        <h4 class="text-sm-center">Date du rendez-vous : </h4>
                        <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center input_resume">
                            <span>
                                <h5 class="text-muted" v-if="!mobile.date || !mobile.start">Merci de choisir une date et une heure</h5>
                                <h5 style="" v-else> {{getDate(selected_date)}}</h5>
                            </span>
                            <button class="btn btn-outline-primary mx-4" title="Modifier la date et l'heure" @click="changeDate()"><i class="mdi mdi-pen"></i></button>
                        </div>
                    </span>

                    <span class="d-flex align-items-center mt-1">
                        <h4>Durée totale: </h4>
                        <div class="input_resume">
                            <h5>{{calculTimes($store.getters.totalDuration)}}</h5>
                        </div>
                    </span>

                    <!-- Validation de la réservation -->
                    <div class="d-flex justify-content-right mt-5">
                        <!-- <button class="btn btn-primary computer" @click="checkOrder()">Valider mes réservations</button> -->
                        <button id="submit_reservation" class="btn btn-primary" @click="createCartMobile()">Valider mes réservations</button>
                    </div>
                </div> <!--border gold-->
            </div>

            <!-- informations -->
            <div class="row mt-4">
                <div class="col-md-12">
                    <h4>Pour toute demande d'information</h4>
                    <div class="row">
                        <div class="col-md-4 ">
                            <div class="card">
                                <div class="card-body">
                                <div class="d-flex flex-row align-items-top">
                                    <i class="mdi mdi-facebook icon-md gold-yellow"></i>
                                    <div class="ml-3">
                                        <h6 class="gold-yellow pointer" @click="facebook()">Mon échappée belle</h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                <div class="d-flex flex-row align-items-top">
                                    <i class="mdi mdi-phone icon-md gold-yellow"></i>
                                    <div class="ml-3">
                                    <h6 class="gold-yellow">06 75 43 75 22</h6>
                                    <p class="mt-2 text-muted card-text"></p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="card">
                                <div class="card-body">
                                <div class="d-flex flex-row align-items-top">
                                    <i class="mdi mdi-instagram text-facebook icon-md gold-yellow"></i>
                                    <div class="ml-3">
                                    <h6 class="gold-yellow pointer" @click="instagram()">@monechappebelle81</h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/fr';
import timePickerMeb from '../components/time-picker-meb.vue'
// import agendaMeb from '../components/agenda-meb.vue';

import {mapState} from 'vuex'
import axios from 'axios';
import addService from '../components/functions/add-service.vue';
import pageLoader from '../components/pageLoader.vue';
// import addServicePlus from '../components/functions/add-service-plus.vue';
import fullTimePickerMeb from '../components/full-time-picker-meb.vue';

export default {
    name: 'reservation',
    components : {
        DatePicker,
        timePickerMeb,
        // agendaMeb,
        // addServicePlus,
        addService,
        pageLoader,
        fullTimePickerMeb
    },
    data () {
        return {
            loader:false,
            modaltriger:false,
            // services:null,
            loadershow:false,
            deliveryinfos:{},
            name:'',
            myDate:[],
            skey:1,
            cart: [
                ],
            search:'',
            firstname:'',
            lastname:'',
            email:'',
            tel:'',
            toName:'',
            schedule:[],
            message:'Traitement des données en cours',
            unavailableschedule:[],
            fulltimepickerkey:1,
            voucher: false,
            timeoncall:null,
            mobile:{
                date:'',
                start:'',
                end:''
            },
            mobileScreen:false,
            key_time_picker:200,
            myreservation:[]
        }
    },
    computed: {
        ...mapState({
            services: state=> state.cart.services,
            token: state=> state.authentification.token
        }),
        totalpice(){
            let total = 0
            this.cart.forEach(element => {
                total = total + element.price
            });
            return total
        },
        isvalid(){
            var check;
            if (this.cart.length > 0) {
                check = true
            }else{
                 check = false
            }
            return check
        },
        totalDuration(){
            let duration = 0
            for ( let item of this.$store.state.cart.services) {
                duration = duration + item.duration
            }
            return this.calculTimes(duration)
        },
        selected_date(){
            return new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
        }
    },
    watch:{
    },
    methods: {
        disabledDate (date) {
            let today = this.datePlusDay(-1)
            return date.getDay()===0 || date.getDay()===1 || date < today
        },
        datePlusDay(nbrofday,date){
            if (!date) {
                date = new Date();
            }
            date =date.setDate(date.getDate() + nbrofday)
            date =new Date(date)
            return date
        },
        async checkOrder(){

            if (!this.isvalid){
                alert('Choisissez au moins une prestation et veuillez choisir une date de rendez-vous')
                return
            }

            // Condition si il y a une réservation faite en parallèle avec les mêmes dates
            const bool = await this.getschedule()
            
            if (bool) {
                let text = ""
                this.unavailableschedule.forEach(element => {
                    text+=" "+this.getDate(element.startDate)+" au "+ this.getDate(element.endDate)
                });
                this.loadershow =false
                this.$swal({
                    text: 'Votre réservation se superpose avec une date déjà existante:'+text+', merci de bien vouloir sélectionner une autre date. 10 mn sont intercalés sur tous les rendez vous',
                    showCancelButton: true,
                    confirmButtonColor: '#0b0b0b',
                    cancelButtonColor: '#fff',
                    cancelButtonText: 'Fermer',
                    confirmButtonText:'Actualiser'
                })
                .then(() => location.reload())
                return
            }
            // message de retour sur la réservation
            let text2=""
            this.$store.state.cart.services.forEach(element => {
                text2+='<div class="d-flex justify-content-between"> <p class="text-left">'+ element.name +'</p> <h3 class="text-right">'+ element.price +'€</h3></div>'
            });
            let textDate = this.cart[0].startDate

            // Confirmation de la réservation
            this.$swal({
                title: 'Voulez-vous confirmer cette réservation?',
                html:text2+'<br> <hr class="mt-2"><p class="mt-2 text-left">Date : ' +this.getDate(textDate)+'</p>',
                showCancelButton: true,
                confirmButtonColor: '#0b0b0b',
                cancelButtonColor: '#fff',
                confirmButtonText: 'Confirmer',
                cancelButtonText: 'Annuler'
            })
            .then((result) => {
                if (result.value) {
                this.loadershow = true;
                this.postMyCart()
                }else{
                    this.loadershow=false
                    // this.cart = [] dans this.createcartmobile ce qui pose un problème sur l'affichage, d'où le reload si sur mobile
                    if(this.mobileScreen){
                        this.mobileScreen=false
                        location.reload()
                    }
                }
            })
            .catch(()=> this.loadershow.false)


        },
        async postMyCart () {
            //si l'utilisateur n'a pas de numéro ou email dans ses informations
            if(!this.phone){
                this.phone = "non mentionné"
            }
            if(!this.email){
                this.email = "non mentionné"
            }
            // création des appointments
            let appointments=[]
            for(let item of this.cart){
                appointments.push({
                    start_hour:this.dateString(item.startDate),
                    end_hour:this.dateString(item.endDate),
                    service:item.prestation.ulid
                })
            }
            let body = {
                description:"reservation",
                reservation_date:new Date ().toISOString().slice(0,16)+"+02:00", //date aujourd'hui format "YYYY-MM-DDTHH-II+02:00",
                staff:process.env.VUE_APP_STAFF,
                sites:process.env.VUE_APP_SITE,
                // companies:process.env.VUE_APP_COMPANY,
                // billingcompany:process.env.VUE_APP_COMPANY,
                on_behalf_of:{
                    name:this.name,
                    phone:this.phone,
                    email:this.email
                },
                address:{
                    name: "Mon échappée belle Gaillac",
                    street1: "64 Av. Saint-Exupery",
                    city: "Gaillac",
                    state: "FR",
                    zip: "81 600",
                    country: "FR",
                    phone: "06 75 43 75 22",
                    email: "contact@monechappeebelle.fr"
                },
                appointments:appointments
            }
            axios.post('myreservations',body)
                .then(resMyReservations => {
                    resMyReservations
                    alert('ajouté avec succès')
                    this.$store.state.cart.services=[]
                    localStorage.removeItem('services')
                    this.cart = []
                    location.reload()
                })
                .catch(errReservation => {
                    // liste des erreurs et messages d'erreur
                    let status_code = errReservation.response.status
                    let error_response = errReservation.response.data
                    if(status_code === 400) {
                        if(error_response.error=="stackable") {
                            alert("Votre réservation se superpose avec une autre, merci de réserver sur un autre créneau");
                        }else if(error_response.message === "appointments cannot be empty" || error_response.message === "appointments cannot be null" || error_response.message ==="reservation must have at least one appointment"){
                            alert("Merci de ne pas laisser la section prestation vide")
                        }else{
                            alert("Une erreur s'est produite, le contenu de votre réservation n'a pas été pris en compte, Veuillez réessayer")
                        }
                    }else if(status_code === 404){
                        if(error_response.message === "services not found"){
                            alert('Prestation non trouvée, code: 404 services not found')
                        }
                        else if (error_response.message === "start_hour not found") {
                            alert('Date de début non définie')
                        }
                        else if (error_response.message === "end_hour not found") {
                            alert('Date de fin non définie')
                        }else{
                            alert('Une erreur 404 est survenue')
                        }
                    }else if(status_code === 500){
                        alert('Une erreur 500 est survenue, merci de bien vouloir nous contacter');
                    }else{
                        alert('Une erreur est survenue dans myreservations, merci de bien vouloir actualiser la page et réessayer')
                    }

                    this.loadershow = false
                    // alert(" Une erreur s\'est produite, le contenu de votre réservation n'a pas été pris en compte, Veuillez réessayer")
                    if(this.mobileScreen) {
                        this.mobileScreen = false
                        location.reload()
                    }
                    location.reload()
                    errReservation
                })
        },
        // envoi d'un email à l'administrateur
        sendMail(ulid){
            axios.post(`myorders/sendmail`,{
                order:ulid
            })
            .then(()=> {
                alert('Un email vous a été envoyé')
                this.loadershow = false
                this.fulltimepickerkey++
                })
            .catch(errSendmail =>{
                console.log(errSendmail)
                alert("erreur survenue lors de l'envoi email ")
            })
        },
        // envoi de notification à l'administrateur
        sendNotification(myorder_ulid){
            axios.post(`notification`,{
                "object":"reservation",
                "object_id":`${myorder_ulid}`,
                "message": `${this.name} a pris une réservation pour le ${this.mobile.date} à partir de ${this.mobile.start}`,
                "receiver":"01G5DVN6ZDHVR6PHYZZFWZN3VW"
            })
            .then(resSendNotification =>{
                console.log({resSendNotification});
            })
            .catch(errSendNotification => {
                console.log({errSendNotification});
            })
        },
        removeServiceToCart(service){
            this.$swal({
            title: 'Suppression',
            text: 'Voulez-vous supprimer cette prestation?',
            showCancelButton: true,
            confirmButtonColor: '#0b0b0b',
            cancelButtonColor: '#fff',
            confirmButtonText: 'Oui, Supprimer',
            cancelButtonText: 'Annuler',
        })
        .then((result) => {
            if (result.value) {
                
            //remove service in vuex
            this.$store.dispatch('removeServiceToCart')
            //remove service in cart
            if (service.endDate) {

                let index = this.myDate.findIndex(object => {
                return object.dateend == this.dateString(service.endDate);
                });

                this.myDate.splice(index, 1);
            }
            this.cart.splice(this.cart.indexOf(service), 1);
            this.changeDate()
            // this.getFirstAndLastDate()
            window.location.href="#prestations"
                this.$swal(
                {
                title:'Supprimé!',
                text:'La prestation a bien été supprimée',
                icon:'success',
                confirmButtonColor: '#0b0b0b',
                confirmButtonText: 'Fermer'
                }
                )          
            }
        })
        },
        calculTimes(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            return timeString
        },
        Createcard(){
            //load service to vuex
            this.$store.state.cart.services.forEach(element => {
                //cardEl data model for cart
                let cardEl = { prestation:[], duration: '', datepurchase: '', startDate: new Date(),endDate: '', price:''}
                cardEl.prestation = element
                cardEl.price = cardEl.prestation.price
                cardEl.duration = this.calculTimes(element.duration)
                cardEl.datepurchase = this.dateString()
                //populate cart
                this.cart.push(cardEl)
            });
        },
        // Réserver depuis time-picker-meb
        changeTime(item,payload){
            //dynamic date change format to h:m string version
            console.log({item})
            if (item.endDate) {
                this.myDate = this.myDate.filter(
                    fil => fil.dateend != this.dateString(item.endDate)
                    
                )
            }
            item.endDate = payload.ed
            item.startDate = payload.sd
            this.myDate.push({
                "dateend": this.dateString(payload.ed),
                "datestart":this.dateString(payload.sd)
            })
            this.sortDate(this.myDate)
        },
        // select heure début mobile
        saveTimeMobile(payload){
            let date = payload.sd //format: Tue Mar 22 2022 09:30:00 GMT+0300 (heure normale d’Afrique de l’Est)
            this.mobile.start = date.getHours() + ':' + (date.getMinutes()<10?'0':'') + date.getMinutes()
            // this.getFirstAndLastDate()
            window.location.href="#resume"
            // this.mobile.end = date.setHours(date.getHours()+duration)
        },
        // créer le cart automatiquement -- mobile version
        async createCartMobile(){
            // condition si heure de début ou date vide
            if(!this.mobile.date || !this.mobile.start){
                alert("Merci de bien vouloir sélectionner la date et l'heure de début")
                return
            }
            this.mobileScreen = true
            let dateNow = new Date()
            let startDate = new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
            // comparaison à date d'aujourd'hui
            if(startDate<dateNow){
                alert("L'horaire que vous avez sélectionné est déjà passé")
                return
            }
            this.cart=[]
            let endDate = new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
            let price = 0
            let ulid =''
            let datepurchase= this.dateString(startDate)
            for (let item of this.$store.state.cart.services) {
                ulid = item.ulid
                price = item.price
                startDate = endDate
                console.log("duration float time", this.floatTimeToHHMM(item.duration) );
                endDate = this.datePlusDuration(startDate, this.floatTimeToHHMM(item.duration))
                await this.cart.push({
                    datepurchase:datepurchase,
                    prestation:{
                        ulid:ulid
                    },
                    startDate: startDate,
                    endDate: endDate,
                    price:price
                })
            }
            
            this.checkOrder()
        },
        // afficher ma réservation sélectionnée dans le full calendar
        async getFirstAndLastDate(){
            let startDate = new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
            let endDate = new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
            let myReservation = []
             for (let item of this.$store.state.cart.services) {
                startDate = endDate
                endDate = this.datePlusDuration(startDate, this.floatTimeToHHMM(item.duration))
                await myReservation.push({
                    startDate: startDate,
                    endDate: endDate
                })
            }
            let myReservation_date = {
                start:"",end:"", class:"myreservation", title:"Ma réservation"
            }
            myReservation_date["start"] = myReservation[0].startDate
            myReservation_date["end"] = myReservation[myReservation.length-1].endDate
            this.myreservation = [myReservation_date]
            this.fulltimepickerkey++
        },
        changeDate(){
            this.mobile.date = ""
            this.mobile.start = ""
            // this.getFirstAndLastDate()
            window.location.href="#fulltimepicker"
        },
        datePlusDuration(date,duration){
            let date2 = new Date(date)
            let time = this.GetTimeInDate(date2)
                let hours = Number(duration.substr(0,2)) + Number(time.substr(0,2))
                date2.setHours(hours)
                let minutes = Number(time.substr(3,2)) + Number(duration.substr(3,2)) 
                date2.setMinutes(minutes)
                return date2
        },
        GetTimeInDate(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes())  
            return stringdate
        },
        floatTimeToHHMM(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            let heure = Number(timeString.substr(0,2))
            let min = Number(timeString.substr(3,5))
            heure = heure < 10 ? '0' + heure : heure
            min = min < 10 ? '0'+min : min
            let stringHeure = heure+':'+ min
            return stringHeure
        },
        // transcrire date en français
        getDate(date){
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            date = date.toLocaleDateString('fr-FR', options)
            return date
        },
        addService(service){
            //repopulate cart 
            let cardEl = { prestation:[], duration: '', datepurchase: '', startDate: '',endDate: '', price:''}
                cardEl.prestation = service
                cardEl.price = cardEl.prestation.price
                cardEl.duration = this.calculTimes(service.duration)
                cardEl.datepurchase = this.dateString()
            this.cart.push(cardEl)
            this.changeDate()
        },
        plusaddservice(data){
            //repopulate cart
            let service =data.service
            let cardEl = { prestation:[], duration: '', datepurchase: '', startDate: '',endDate: '', price:''}
                cardEl.prestation = service
                cardEl.price = cardEl.prestation.price
                cardEl.duration = this.calculTimes(service.duration)
                cardEl.datepurchase = data.datepurchase
                cardEl.startDate= data.sd
                cardEl.endDate= data.ed
            this.cart.push(cardEl)
            this.myDate.push({
                "dateend": this.dateString(data.ed),
                "datestart":this.dateString(data.sd)
            })
            this.sortDate(this.myDate)
            
        },
        dateString(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
        redirect() {
            this.$router.push ({
                name: "login"
            })
        },
        sortDate(array){
            return array.sort((a,b) => {
                let test =Date.parse("2022-03-24 09:30:00")
                let a_datestart = Date.parse(a.datestart)
                let b_datestart = Date.parse(b.datestart)
                console.log("test date", test);
                if(a_datestart > b_datestart) return 1;
                if(a_datestart < b_datestart) return -1;
                return 0;
            });
        },
        sort(){
            this.cart.sort((a,b) => {
                if(a.startDate.getTime() > b.startDate.getTime()) return 1;
                if(a.startDate.getTime() < b.startDate.getTime()) return -1;
                return 0;
            });
        },
        checkDate(item){
            let ed = true
            let cart = this.cart.filter( el  => item.datepurchase  == el.datepurchase)
            cart.forEach(element => {
                if (element.endDate == "") {
                    ed = false
                }
            })
            return cart[cart.length - 1] == item && ed
        },
        instagram(){
            window.location.href="https://www.instagram.com/monechappebelle81/"
        },
        facebook(){
            window.location.href="https://www.facebook.com/monechappeebelle81/"
        },
        getselectedHours(item){
            if(item.endDate){
                return {
                    dateend:this.dateString(item.endDate),
                    datestart:this.dateString(item.startDate)
                }
            }else{
                return {}
            }
        },
        async getschedule(){
            this.loadershow =true
            this.message ="Verification des dates"
            //  await axios.get('datescheduledservices')
             await axios.get('schedules/appointments')
            .then(
                resschedule => {
                    this.schedule =resschedule.data.result
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                }
            )

            if(this.schedule){
                this.cart.forEach(cartobject => {
                    this.schedule.forEach(scheduleobject => {
                        if (
                            // schedule contenu dans cart
                            this.dateToNumber(this.dateString(cartobject.startDate)) >= this.dateToNumber(scheduleobject.datestart)  &&  
                            this.dateToNumber(this.dateString(cartobject.startDate)) <= this.dateToNumber(scheduleobject.dateend)  ||
                            // endDate entre le schedule
                            this.dateToNumber(this.dateString(cartobject.endDate))  >= this.dateToNumber(scheduleobject.datestart)  &&
                            this.dateToNumber(this.dateString(cartobject.endDate)) <= this.dateToNumber(scheduleobject.dateend)||
                            // schedule englobé dans cart
                            this.dateToNumber(this.dateString(cartobject.startDate)) <= this.dateToNumber(scheduleobject.datestart) &&
                            this.dateToNumber(this.dateString(cartobject.endDate)) >= this.dateToNumber(scheduleobject.dateend)
                            ) 
                        {
                            this.unavailableschedule.push(cartobject)
                        }
                    });
                });
            }
            this.message="Traitement des données en cours"
            if (this.unavailableschedule.length > 0 ) {
                return true
            }
            if(this.unavailableschedule.length == 0){
                return false
            }
        },
        dateToNumber(stringDate){
            let date = stringDate.substr(0,16)
            date = date.replace('-','')
            date = date.replace('T','')
            date = date.replace(':', '')
            date = date.replace('-','')
            date = date.replace(' ','')
            date = Number(date)
            return date
        },
        formatDateStringToStandar(date){
            let y = date.substr(0,4)
            let m = date.substr(5,2)
            let d = date.substr(8,2)
            return m+"/"+d+"/"+y
        },
        getInfo(){
            this.loadershow = true
                axios.get('myinfos')
                .then(resGeter => {
                this.loadershow = false
                this.deliveryinfos=resGeter.data.mainaddress
                this.name = resGeter.data.firstname+' '+ resGeter.data.lastname
                this.phone = resGeter.data.tel
                this.email = resGeter.data.email
            })
            .catch(errGet => {
                alert('Merci de bien vouloir vous connecter')
                this.$router.push({
                    name:'login'
                })
                this.loadershow = false
                console.log({errGet})

            })
        },
        alertPrestation(){
            alert('Ajouter au moins une prestation avant de choisir une date')
        }
    },
    mounted(){
    },
    created() {
        this.Createcard()
        this.getInfo()
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');
        // axios.defaults.headers.common['Cache-control'] = "no-cache, must-revalidate";
    }
}
</script>

<style lang="scss" scoped>
.cream-background {
    position: relative;
    min-height: 30rem;
    background-image: url('../assets/images/import/cream.png');
    background-size: 80%;
    background-position:top -17rem right -4rem;
    background-repeat: no-repeat;
}
.accordion.accordion-bordered .card .card-header a::before {
    color: #e0d075;
}
.accordion.accordion-solid.accordion-bordered {
    box-shadow: 0px 1px 15px 1px rgba(177, 177, 177, 0.35) !important; 
}
/* mettre à la ligne lorsque la table est pleine */
td {
    white-space: break-spaces
}
.accordion-title{
    display: flex !important;
    flex-direction: column !important;
}
.accordion .card .card-header a::before{
    display: flex !important;
}
.reservation-mobile .card-body{
    /* background: white; */
    box-shadow: 1px 3px 5px gainsboro;
    border-radius: 4px;
}
.construct{
height: 25px;
width: 25px;
border-radius: 50%;
position: absolute;
right: -10px;
top: -7px;
font-size: 17px;
padding-right: 0px;
padding-left: 0.5px;
padding-bottom: 0px;
padding-top: 3px;
}
.unavailable_square {
    width: 2rem;
    height: 2rem;
    background: #fffaf0 repeating-linear-gradient(-45deg,hsla(0,0%,87.5%,.25),hsla(0,0%,82.4%,.25) 5px,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 15px);
    color: #f6984c;
}

#resume{
    background-image: url('../assets/images/import/fond_beige.png');
    padding: 1rem;
    .border_gold{
        background: url('../assets/images/import/Feuilles_or.png') no-repeat;
        background-position: right -4rem bottom -2rem;
        width: 100%;
        height: 100%;
        border: 1px #cdb164 solid;
        padding:4rem;
    }
    .input_resume {
        min-width: 20rem;
        // background: white;
        padding: 5px;
        margin-left: 1rem;
    }
}

@media screen and (max-width: 992px) {
    #resume {
        .border_gold{
            padding: 1.5rem;
        }
        h4{
            text-align: center;
            margin-top:1rem;
        }
    }
}
</style>
<style>
html{
    scroll-behavior: smooth !important;
}
</style>