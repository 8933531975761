<template>
    <section class="section-add-service mb-3">
        <button  v-b-modal="modalId"  class="btn btn-block btn-secondary">{{text}}</button>
        <b-modal :id="modalId"  size="lg" hide-footer hide-header>
            <!-- loader -->
            <div v-if="!searchServiceResult" class="jumping-dots-loader">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <b-card v-else class="p-2" no-body>
                <div class="row m-4">
                    <div class="computer col-md-8">

                        <!-- tags des prestations sélectionnées -->
                        <div>
                            <h4>Prestations sélectionnées</h4>
                            <div class="badge badge-gradient-primary mb-1" v-for="(service,serviceID) of arrayOfAddedService" :key="serviceID">
                               {{ service.name }} <span class="pointer ml-2" @click="onTagRemoved(service, serviceID)">x</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="allService" class="col-md-4 col-sm-12 p-0">
                        <div class="input-group">
                            <input v-model="inputSearch"  @keyup="searchService()" class="form-control" id="navbar-search-input" placeholder="Rechercher un service" aria-label="search" aria-describedby="search">
                            <div class="input-group-append">
                                <button type="submit" class="input-group-text"><i class="fa fa-search icon-sm"></i></button>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- affichage Ordinateur -->
                <b-tabs class="computer" pills card vertical nav-wrapper-class="w-50">
                    <b-tab title="Tous" @click="allService =true">
                        <b-list-group>
                            <b-list-group-item href="#" @click="selectedService = item"  v-for="(item, index) in searchServiceResult" :key="index">
                                <div class="row">
                                    <div class="col-md-6">{{item.name}}</div>
                                    <div class="col-md-2">{{calculTimes(item.duration)}}</div>
                                    <div class="col-md-2" style="text-align:right">{{item.price}} €</div>
                                    <div class="col-md-2">
                                        <!-- <button :id="`button_${index}`" class="btn btn-secondary btn-rounded btn-icon" @click="fastAddService(item)"><i class="mdi mdi-plus"></i></button> -->
                                        <button class="btn btn-secondary btn-rounded btn-icon" @click="addServiceToArray(item)"><i class="mdi mdi-plus"></i></button>
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </b-tab>
                    <b-tab  @click="allService =false" :title="item.category"  v-for="(item, index) in categorie" :key="index" >
                        <b-list-group>
                            <b-list-group-item href="#" @click="selectedService = item"  v-for="(item, index) in item.services" :key="index">
                                <div class="row">
                                    <div class="col-md-6">{{item.name}}</div>
                                    <div class="col-md-2">{{calculTimes(item.duration)}}</div>
                                    <div class="col-md-2" style="text-align:right">{{item.price}} €</div>
                                    <div class="col-md-2">
                                        <!-- ajout et fermeture direct  -->
                                        <!-- <button class="btn btn-secondary btn-rounded btn-icon" @click="fastAddService(item)"><i class="mdi mdi-plus"></i></button> -->
                                        <!-- ajout multiple -->
                                        <button class="btn btn-secondary btn-rounded btn-icon" @click="addServiceToArray(item)"><i class="mdi mdi-plus"></i></button>
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </b-tab>
                </b-tabs>

                <!-- affichage mobile -->
                <b-tabs class="mobile tab-solid tab-solid-danger">
                    <b-tab title="Tous" @click="allService =true">
                        <b-list-group>
                            <b-list-group-item href="#" @click="selectedService = item"  v-for="(item, index) in searchServiceResult" :key="index">
                                <div class="row">
                                    <div class="col-md-12">{{item.name}}</div>
                                    <div class="col-md-12 d-flex justify-content-between">
                                        <span>
                                            <div>{{calculTimes(item.duration)}}</div>
                                            <div>{{item.price}} €</div>
                                        </span>
                                        <button class="btn btn-secondary btn-rounded btn-icon" @click="fastAddService(item)"><i class="mdi mdi-plus"></i></button>
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </b-tab>
                    <b-tab  @click="allService =false" :title="item.category"  v-for="(item, index) in categorie" :key="index" >
                        <b-list-group>
                            <b-list-group-item href="#" @click="selectedService = item"  v-for="(item, index) in item.services" :key="index">
                                <div class="row">
                                    <div class="col-md-12">{{item.name}}</div>
                                    <div class="col-md-12 d-flex justify-content-between">
                                        <span>
                                            <div>{{calculTimes(item.duration)}}</div>
                                            <div>{{item.price}} €</div>
                                        </span>
                                        <button class="btn btn-secondary btn-rounded btn-icon" @click="fastAddService(item)"><i class="mdi mdi-plus"></i></button>
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </b-tab>
                </b-tabs>
            </b-card>

            <!-- bouton de validation -->
            <hr>
            <div class="d-flex justify-content-end computer">
                <button class="btn btn-link mx-4" @click="closeAddServiceModal()">Annuler</button>
                <button class="btn btn-primary mx-4" @click="addService(false)">Valider</button>
            </div>
        </b-modal>
    </section>
</template>
<script>
import axios from 'axios'

export default {
    name: 'add-service',
    data () {
        return {
            selectedService: null,
            inputSearch:'',
            searchServiceResult:null,
            allService:true,
            categorie:null,
            services:null,
            arrayOfAddedService:[],
            value:[]
        }
    },
    props:{
        text:{
            type:String,
            default:"Ajouter un rendez-vous"
        },
        modalId:{
            type:String,
            default:"modal"
        }
    },
    methods:{
        getCategories(){
            let url = "categories"
            axios.get(url)
                .then(
                    resgetcategories => {
                        this.categorie = resgetcategories.data.result
                        this.categorie = this.categorie.filter(element => element.ulid !="01FY46MX2EW85PP30KQ6S9BXG3")
                    }
                )
                .catch(
                    errgetProducts => {
                        console.log(errgetProducts)
                    }
                )
        },
        getService(){
            let url ="services"
            axios.get(url)
                .then(
                    resgetService => {
                        this.services = resgetService.data.data
                        this.services = this.services.filter(service => service.isactive)
                        console.log(this.services);
                        this.searchServiceResult =this.services
                    }
                )
                .catch(
                    errgetProducts => {
                        console.log(errgetProducts)
                    }
                )
        },
        select(item){
            this.selectedService=item
        },
        // sélection multiple de service
        addServiceToArray(item){
            // vérifier si l'élément n'est pas déjà dans la liste
            let repeatedService = this.arrayOfAddedService.includes(item)
            let repeatedServiceInAgenda = this.$store.state.cart.services.includes(item)
            console.log({repeatedServiceInAgenda});
            // ajouter la prestation si pas encore dans la liste
            if(!repeatedService && !repeatedServiceInAgenda){
                this.arrayOfAddedService.push(item)
            }else{
                alert('Prestation déjà dans la liste') 
            }
        },
        // fermeture direct du modal après ajout
        fastAddService(item){
            this.selectedService = item
            this.addService(true)
        },
        async addService(mobile){
            if(mobile){
                // sur mobile, ajout directe sans parcours du tableau des prestations multiples
                if(this.selectedService){
                    // ajouter 'une' service dans le panier du store
                    this.$store.dispatch('add_service', this.selectedService)
    
                    this.$snotify.success("Prestation ajoutée au panier", {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        });
                        
                    // ajouter le service dans le tableau de réseration + calcul des horaires et prix
                    this.$emit('updateService',this.selectedService)
                    this.selectedService=null, this.arrayOfAddedService = []
                    this.$bvModal.hide(this.modalId)
                }
                else{
                    alert('Veuillez sélectionner une prestation')
                }
            }else{
                // sur ordinateur, parcours du tableau et ajout un par un des services dans le tableau
                if (this.arrayOfAddedService.length > 0) {
                    for (let service of this.arrayOfAddedService){
                        // ajouter 'une' service dans le panier du store
                        await this.$store.dispatch('add_service', service)
                        // ajouter le service dans le tableau de réseration + calcul des horaires et prix
                        await this.$emit('updateService',service)
                    }
                    this.selectedService=null, this.arrayOfAddedService = []
                    this.$bvModal.hide(this.modalId)
                }else{
                    alert('Veuillez sélectionner une prestation')
                }
            }

        },
        searchService()
        {
            this.searchServiceResult=null
            this.currentPage = 1
            const regex = new RegExp(this.inputSearch.toLowerCase());
            let newservice = []
            this.services.forEach(element => {
                if(regex.test(element.name.toLowerCase())){
                    newservice.push(element)
                }
            })
            this.searchServiceResult = newservice
        },
        convertToTime(duration){
            var sec = 3600 * duration
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 8);
            return timeString
        },
        calculTimes(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            let heure = Number(timeString.substr(0,2))
            let min = Number(timeString.substr(3,5))
            let stringHeure = ''
            if (heure) {
                stringHeure = heure+ ' h'
            }
            if (min) {
                stringHeure += min + 'mn'
            }
            return stringHeure
        },
        onTagRemoved(element,index){
            this.arrayOfAddedService.splice(index,1)
        },
        closeAddServiceModal(){
            this.$bvModal.hide(this.modalId)
        }
    },
    mounted(){
        this.getCategories()
        this.getService()

        // réinitialiser les données du modal à la fermeture
        this.$root.$on('bv::modal::hide', () => {
            this.selectedService=null, this.arrayOfAddedService = []
        })
    }
}
</script>
<style scoped>
::v-deep .tab-pane.active {
    max-height: 50vh;
    overflow: auto;
}
::v-deep .tab-solid-danger .nav-link.active{
    background: #d69c2e;
    color: #fff;
}
::v-deep .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    background-color: #d69c2e !important;
    color: #fff;
}

::v-deep .list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #ffe6b8;
}

.btn-secondary {
    background: rgb(205, 165, 37);
}
.btn-secondary:hover {
    background: rgb(158, 126, 23);
}
.btn-secondary:focus {
    background: rgb(205, 165, 37);
}

@media screen and (max-width: 992px) {
    .section-add-service{
        width: 100%
    }
}
</style>